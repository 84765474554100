import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to business</title>
);

export const BusinessPage = ({ data }: PageProps<Queries.TopicVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with business videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to business, finance, economics etc. We
              have <Link to="/">lots of other videos</Link> if you&apos;re
              interested in other topics.
            </p>
            <p>
              Business in China is a rapidly growing and increasingly complex
              sector. With the world&apos;s second-largest economy, China is a
              major player in the global business landscape and is quickly
              becoming a hub for innovation and investment. Companies of all
              sizes are taking advantage of China&apos;s rapidly growing
              consumer market, while multinational corporations are leveraging
              the country&apos;s vast resources and cheap labor to maximize
              their profits.
            </p>

            <p>
              At the same time, doing business in China can be a daunting task.
              Regulations and laws are constantly changing, and navigating
              through the local bureaucracy can be a challenge. With its complex
              landscape and ever-changing regulations, understanding the ins and
              outs of doing business in China is essential for success. This
              series of videos will help you better understand the business
              environment in China.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default BusinessPage;

export const pageQuery = graphql`
  query TopicVideos {
    allVideosYaml(filter: { topics: { in: "business" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
